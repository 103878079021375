/*------------------------------------*\
    #MISC
\*------------------------------------*/

hr {
  margin-top: 3rem;
  margin-bottom: 3.5rem;
  border-width: 0;
  border-top: 1px solid #E1E1E1; }

// Typekit FOUT Fix
.wf-loading {
  visibility: hidden;
}



/* Clearing
-------------------------------------------*/

/* Self Clearing Goodness */
.container:after,
.row:after,
.u-cf {
  content: "";
  display: table;
  clear: both; }


/* IE SVG Sizing FIX
-------------------------------------------*/

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%;
  }

  img.grid-item_icon[src$=".svg"] {
    width: 120px;
  }
}
