/*------------------------------------*\
    #UTILITIES
\*------------------------------------*/

.u-full-width {
  width: 100%;
  box-sizing: border-box;
}
.u-max-full-width {
  max-width: 100%;
  box-sizing: border-box;
}
.u-pull-right {
  float: right;
}
.u-pull-left {
  float: left;
}
.u-center {
  margin: 0 auto;
}
.u-center-table {
  display: table;
  @extend .u-center;
}
.u-left {
  text-align: left;
}
.u-right {
  text-align: right;
}
.u-text-center {
  text-align: center;
}
.u-text-left {
  text-align: left;
}
.u-text-right {
  text-align: right;
}
.u-flex {
  display: flex;
}
.u-flex-center {
  display: flex;
  justify-content: center;
}
.u-hide-text {
  @include hide-text;
}
.u-bold {
  font-weight: 700;
}
.u-black {
  font-weight: 900;
}
.u-light {
  font-weight: 300;
}
.u-uppercase {
  text-transform: uppercase;
}
.u-spaced {
  letter-spacing: 3px;
}
.u-spaced-small {
  letter-spacing: 1px;
}
.u-flex-v-center {
  align-items: center;
  justify-content: center;
}
.u-flex-v-top {
  align-self: flex-start;;
}
.u-margin-base {
  margin: $base-spacing 0;
}
.u-margin-small {
  margin: $small-spacing 0;
}
.u-margin-large {
  margin: $large-spacing 0;
}
.u-margin-bottom-base {
  margin-bottom: $base-spacing;
}
.u-margin-bottom-small {
  margin-bottom: $small-spacing;
}
.u-margin-top-large {
  margin-top: $large-spacing;
}
.u-margin-bottom-large {
  margin-bottom: $large-spacing;
}
.u-padding-0 {
  padding: 0;
}
.u-padding-top-0 {
  padding-top: 0;
}
.u-margin-top-0 {
  margin-top: 0;
}
.u-margin-bottom-0 {
  margin-bottom: 0;
}
.u-fill-blue {
  fill: $blue;
}
.u-color-dark-gray {
  color: $dark-gray;
}
.u-text-decoration-none {
  text-decoration: none;
}
.u-list-style-type-none {
  list-style-type: none;
}
.u-font-title {
  font-family: $title;
}
.u-clear-both {
  clear: both;
}
