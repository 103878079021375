/*------------------------------------*\
    #LISTS
\*------------------------------------*/

ul {
  list-style: disc inside;
}
ol {
  list-style: decimal inside;
}
ol, ul {
  padding-left: 0;
  margin-top: 0;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin: 1.5rem 0 1.5rem 3rem;
  font-size: 90%;
}
li {
  margin-bottom: 1rem;
}

.tab-pane {
  display: none;
}

.tab-pane.active {
  display: block;
}

.tabs {
  li {
    position: relative;
    padding: $base-spacing;
    border: 1px solid transparent;
    border-radius: $border-radius;
    @include transition;
    &.active {
      background-color: #fff;
      border-color: rgba($blue, .2);
      @media (max-width:$breakpoint) {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
  p {
    color: $main-color;
    margin-bottom: 0;
  }
  a {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  .tab-container {
    padding: $base-spacing;
  }
}
