/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/

.button, button, input[type="submit"],input[type="reset"],input[type="button"] {
  @include transition;
  display: inline-block;
  height: 45px;
  padding: 0 30px;
  color: white;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 45px;
  text-transform: uppercase;
  font-family: $title;
  letter-spacing: 1px;
  text-decoration: none;
  white-space: nowrap;
  background-color: $link-color;
  border-radius: $border-radius;
  border: none;
  cursor: pointer;
  position: relative;
  &:hover, &:focus {
    background-color: darken($link-color, 5%);
    color: white;
  }
  &:active {
    top: 1px;
  }
}

.button-inline {
  margin-top: $small-spacing;
  width: 100%;
  @media (min-width: $phablet) {
    width: auto;
    margin-top: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -5px;
  }
}

.button-ghost {
  background-color: transparent;
  border: 2px solid $blue;
  color: $blue;
  &:hover, &:focus {
    background-color: $blue;
  }
}
