/*------------------------------------*\
    #GLOBAL
\*------------------------------------*/

html {
  font-size: 62.5%;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, * {
  box-sizing: border-box;
}
body {
  font-size: 1.6em; /* currently ems cause chrome bug misinterpreting rems on body element */
  line-height: 1.6;
  font-weight: 400;
  font-family: $body;
  color: $dark-gray;
  background-color: white;
}
img {
  max-width: 100%;
  height: auto;
}

.logo {
  display: block;
  text-align: center;
  margin: auto;
  @media (min-width: $breakpoint-nav) {
    float: left;
  }
  img {
    max-width: 150px;
  }
}

/* ROWS */

.flex-image-row {
  flex-direction: column-reverse;
  @media (min-width: $breakpoint) {
    flex-direction: row;
  }
}

/* SECTIONS */

.hero {
  background-color: rgba($blue, 0.8);
  height: 400px;
  .header-title,
  .large {
    color: white;
    text-transform: uppercase;
  }

  @media (min-width: $breakpoint-large) {
    height: 500px;
  }

  @media (min-width: $desktop) {
    height: 600px;
  }
}

#home .hero {
  background-image: url(#{$image_path}/home-hero.jpg);
  background-size: cover;
  background-position: center center;
}

#developers .hero {
  background-image: url(#{$image_path}/dev-hero.jpg);
  background-size: cover;
  background-position: center center;
}

.map {
  height: 600px;
  @media (min-width: $breakpoint-large) {
    height: 500px;
  }

  @media (min-width: $desktop) {
    height: 600px;
  }
}

.section-light-blue {
  background-color: $light-blue;
}

.section-white-gradient {
  background-image: -webkit-linear-gradient(rgba(255,255,255,0.0) 0%, #FFFFFF 100%);
  background-image: -o-linear-gradient(rgba(255,255,255,0.0) 0%, #FFFFFF 100%);
  background-image: linear-gradient(rgba(255,255,255,0.0) 0%, #FFFFFF 100%);
}

.section-base {
  padding: $base-spacing 0;
}

.section-large {
  padding: $base-spacing 0;
}


.section-half-height {
  min-height: 50vh;
}


@media (max-width: $breakpoint-large ) {
  .section-breakpoint-large {
    .col {
      width: 100%;
      margin: 1%;
      text-align: center;
    }
  }
}

@media (min-width: $breakpoint) and (max-width: $breakpoint-large ) {
  .col.hero {
    width:60%;
    margin: 1% 20%;
  }
}
#features .hero .container {
  display:block;
  width:100%;
  height:100%;
}
#features .hero {
  padding:0;
}
#features .hero h1 {
  margin-left:55%;
  margin-top:45px;
}
@media (max-width: 960px) {
  #features .hero .container {
    background: rgba(21, 122, 185, 0.5);
  }
  #features .hero h1 {
    margin: 40px auto 0 auto;
  }
}
@media (min-width: 960px) and (max-width: 1020px) {
  .hero h1 {
    font-size:4.5rem;
  }
}
.section-hero_interface {
  background-image:url(#{$image_path}/feature-header.png),url(#{$image_path}/feature-bg.gif);
  background-repeat: no-repeat,repeat-x;
  background-position: center bottom;
  text-align: center;
  align-items: flex-start;

  @media (min-width: 640px) and (max-width: 960px) {
    padding-top: 0;

    text-align: center;
  }
  @media (max-width: $breakpoint-large - 1px)  {
    background-image:url(#{$image_path}/feature-header.png);
    background-color: rgba($blue, 0.8);
    background-size: 100%;
    background-blend-mode: multiply;
  }
  @media (min-width: $breakpoint-large)  {
    background-size: 55%, cover;
    background-position: 10% 10%;
    padding-top: 0;
    text-align: left;
    height: 500px;
    align-items: center;
    background-blend-mode: normal ;
  }

  @media (min-width: $desktop)  {
    height: 600px;
  }

  @media (min-width: $hd) and (max-width: 2200px) {
    background-position: 15%;
    background-size: contain;
  }

  @media (min-width: 2201px) {
    background-position: 15%;
    background-size: contain;
  }
}
.interface-gradient_hero {
  min-height: 200px;
  margin-top: -160px;
  @media (min-width: $phablet) {
    margin-top: -100px;
  }
  @media (min-width: $breakpoint-large) {
    margin: 0;
    display: none;
    height: 0;
  }
}

/* BOXES */

.box {
  border: 2px solid;
  padding: $base-spacing*1.5 $base-spacing;
}

.box_form {
  margin-bottom: $base-spacing;
  input {
    margin-bottom: 0;
  }
}

.box_title {
  margin-bottom: $base-spacing*1.5;
}

.box_link {
  font-size: 1.2rem;
  margin: 0;
  display: inline-block;
}

.box-blue {
  border-color: $blue;
}

.avatar {
  max-width: 260px;
  min-width: 220px;
}

/* GRID ITEMS */

.grid-item_title {
  @media (min-width: $breakpoint) {
    min-height: 200px;
  }
}

.grid-item_icon {
  width: 120px;
  margin-bottom: $base-spacing;
}

.grid-item_image {
  width: 96%;
  margin-bottom: $base-spacing;
}

.interface-gradient {
  min-height: 137px;
  margin-top: -137px;
  @media (min-width: $breakpoint) {
    min-height: 300px;
    margin-top: -300px;
  }
}

.interface-overlay {
  margin-top: -40px;
  @media (min-width: $breakpoint) {
    margin-top: -100px;
  }
}

.interface-overlay_content {
  margin: auto;
  @media (min-width: $breakpoint) {
    width: 60%;
  }
}


/* FOOTER */

.footer-primary {
  border-top: $border;
  font-size: 1.4rem;
}

.footer-social-link {
  display: inline-block;
  &:first-of-type {
    margin-right: 1rem;
  }
}
.footer-social-links {
  @media (min-width: $breakpoint) {
    float: right;
  }
}

/* GRIDS */

.flex-grid {
  flex-flow: row wrap;
}

.member-item {
  flex-basis: 98%;
  padding: $base-spacing;
  @media (min-width: $breakpoint) {
    padding: $large-spacing;
    flex-basis: 50%;
  }
  @media (min-width: $desktop) {
    flex-basis: 33.33%;
  }
}

.job-item {
  &:not(:first-of-type) {
    border-top: $border;
  }
}
