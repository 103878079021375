/*
Project:      Bass - A smart, lightweight Flexbox grid, built with Sass
Project URI:  http://timpotter.github.io/bass-grid
Version:      1.3
Updated:      16th March 2016
Author:       Tim Potter
Author URI:   http://littlethunder.co
*/

// Universal Box Sizing
// ------------------------------------------------------------------------------------------------------------------------------------------

*,
*:before,
*:after {
  box-sizing: border-box;     // Applies a natural box layout model to all elements
}

// Variables
// ------------------------------------------------------------------------------------------------------------------------------------------

// Column Setup
$base-width: 100%;            // Best to leave this at 100%
$columns: 12;                 // Number of columns. 12 is used as default it's easily divisible, but you could also have 16, 24 etc
$base: $base-width/$columns;  // Calculates single column width - 100% / 12 = 8.33333%

// Gutter and Padding
$gutter: 2%;                  // Space between columns (px, %, em, or rem)
$padding: 20px 10px;          // Padding of each column (you can also use shorthand 40px 10px)

// Widths
$breakpoint: 640px;           // Point to snap to single column layout
$container-width: 1230px;     // Sets the width of the container if needed


// Placeholders
// ------------------------------------------------------------------------------------------------------------------------------------------

%column-style {
  float: left;               // For older browsers - has no effect on Flexbox
  margin: $gutter/2;         // Gutter divided by 2 to sit on left and right margins equally
  padding: $padding;
}

// Mixins
// ------------------------------------------------------------------------------------------------------------------------------------------

@mixin column ($column:$columns) {
  width: calc(#{$base*$column} - #{$gutter});
  @extend %column-style;     // Inherit column style

  @media screen and (max-width: $breakpoint) {
    width: calc(#{$base-width} - #{$gutter}); // Resets all columns to full width once breakpoint has been reached
  }
}

@mixin offset ($column:1) {
  margin-left: calc(#{$base*$column} + #{$gutter/2});

  @media screen and (max-width: $breakpoint) {
    margin-left: $gutter/2; // Removes offset once breakpoint has been reached
  }
}

/* Flex Container - Enables a flex context for all its direct children
------------------------------------------------------------------------------------------------------------------------------------------ */

.container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: $container-width;
  margin:0 auto;
  clear: both;
  @media screen and (max-width: $container-width) {
    width: $base-width;     // Sets container to 100% when less than $container-width
  }
}

.container-small {
  margin: auto;
  max-width: 760px;
}

/* Makes images responsive
------------------------------------------------------------------------------------------------------------------------------------------ */

img {
  max-width: 100%;
}

/* Columns
------------------------------------------------------------------------------------------------------------------------------------------ */
// Loop to create the number of specified $columns, output as .col-1, .col-2 etc


@for $i from 1 through $columns {
  .col-#{$i} {
    @include column($i);
  }
}

/* Offsets for positioning columns
------------------------------------------------------------------------------------------------------------------------------------------ */
// Loop to create the offset columns, output as .offset-by-1, .offset-by-2 etc

@for $i from 1 through $columns - 1 {
  .offset-by-#{$i} {
    @include offset($i);
  }
}

/* Use .parent to remove padding from parent column when nesting child columns
------------------------------------------------------------------------------------------------------------------------------------------ */

.parent {
  padding: 0;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $breakpoint) {
    flex-direction: column;
  }

  > div {
    margin: 0;
    @media screen and (max-width: $breakpoint) {
      width: 100%;
    }
  }
}


/* Clearfix
------------------------------------------------------------------------------------------------------------------------------------------ */

.group:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    }
