/*------------------------------------*\
    #FORMS
\*------------------------------------*/

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
  height: 45px;
  padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
  background-color: #fff;
  border: $border;
  border-radius: $border-radius;
  box-shadow: none;
  box-sizing: border-box;
  &:focus {
    border: 1px solid $link-color;
    outline: 0;
  }
}
/* Removes awkward default styles on some inputs for iOS */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
textarea {
  min-height: 65px;
  padding-top: 6px;
  padding-bottom: 6px;
}
label,
legend {
  display: block;
  margin-bottom: .5rem;
  font-weight: 600; }
fieldset {
  padding: 0;
  border-width: 0; }
input[type="checkbox"],
input[type="radio"] {
  display: inline; }
label > .label-body {
  display: inline-block;
  margin-left: .5rem;
  font-weight: normal; }

input.input-inline {
  width: 100%;
  @media (min-width: $phablet) {
    width: auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
