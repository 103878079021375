/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/

h1, h2, h3, h4, h5, h6 {
  font-family: $title;
  color: $title-color;
  margin: 0;
  margin-bottom: 2rem;
}

.h1 {
  font-size: 3.6rem;
  line-height: 1.25;
}
.h2 {
  font-size: 3.0rem;
  line-height: 1.3;
  // text-transform:uppercase;
}
.h3 {
  font-size: 2.4rem;
  line-height: 1.35;
}
.h4 {
  font-size: 1.8rem;
  line-height: 1.5;
}
.h5 {
  font-size: 1.5rem;
  line-height: 1.6;
}
.h6 {
  font-size: 1.5rem;
  line-height: 1.6;
}

@media (min-width: $breakpoint) {
  .h1 {
    font-size: 4.8rem;
  }
  .h2 {
    font-size: 3.6rem;
  }
  .h3 {
    font-size: 2.8rem;
  }
  .h4 {
    font-size: 2.4rem;
  }
  .h5 {
    font-size: 2.4rem;
  }
  .h6 {
    font-size: 1.5rem;
  }
}

p {
  margin-top: 0;
  &.small {
    font-size: 1.2rem;
  }
}

p.large, .post-content>p:first-of-type  {
  font-size: 2.0rem;
  line-height: 1.5;
}

address {
  font-style: normal;
}
.header-title {
  text-align: center;
  @media (min-width: $breakpoint-large) {
    text-align: left;
  }
}

.post-content {
  h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
  }
  h1 {
    font-size: 3.6rem;
    line-height: 1.25;
  }
  h2 {
    font-size: 3.0rem;
    line-height: 1.3;
  }
  h3 {
    font-size: 2.4rem;
    line-height: 1.35;
  }
  h4 {
    font-size: 1.8rem;
    line-height: 1.5;
  }
  h5 {
    font-size: 1.5rem;
    line-height: 1.6;
  }
  h6 {
    font-size: 1.5rem;
    line-height: 1.6;
  }

  @media (min-width: $breakpoint) {
    h1 {
      font-size: 4.8rem;
    }
    h2 {
      font-size: 3.6rem;
    }
    h3 {
      font-size: 2.8rem;
    }
  .h4 {
      font-size: 2.4rem;
    }
    h5 {
      font-size: 2.4rem;
    }
    h6 {
      font-size: 1.5rem;
    }
  }
}
