/*------------------------------------*\
    #LINKS
\*------------------------------------*/
a {
  color: $link-color;
  @include transition;
  &:hover, &:focus {
    color: darken($link-color,9%);
  }
}

.link-navigate {
  position: relative;
  &:before, &:after {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    @include transition;
    content: "";
    width: 11px;
    top: calc(50% - 6px);
    height: 12px;
  }
}

.link-navigate_back {
  margin-left: 15px;
  &:before {
    content: "";
    background-image:: url(#{$image_path}/triangle.svg);
    left: -20px;
  }
}

a.link-navigate_back:hover:before {
  left: -23px;
}

.link-navigate_forward {
  margin-right: 15px;
  &:after {
    content: "";
    background-image: url(#{$image_path}/triangle.svg);
    transform: rotate(-180deg);
    right: -20px;
  }
}

a.link-navigate_forward:hover:after {
  right: -23px;
}

.link-navigate_forward.link-navigate_disabled:after {
  background-image: url(#{$image_path}/triangle-grey.svg);
}

.link-navigate_back.link-navigate_disabled:before {
  background-image: url(#{$image_path}/triangle-grey.svg);
}


.pagination {
  width: auto;
  .link-navigate {
    padding: 20px 10px;
    width: 20px;
    &:before, &:after {
      background-size: 80%;
      top: calc(50% - 10px);
      width: 21px;
      height: 22px;
    }
  }
  .link-navigate_forward {
    float: right;
  }
}

.page_number {
  border: 1px solid $blue;
  line-height: 40px;
  display: inline-block;
  padding: 0 20px;
  font-weight: bold;
  color: $blue;
}
