/*------------------------------------*\
    #NAVIGATION
\*------------------------------------*/

// Active States

#home .home a,
#team .team a,
#features .features a,
#developers .developers a,
#contact-us .contact-us a,
#blog .blog a,
#jobs .jobs a {
 font-weight: 900;
 border-bottom: 2px solid currentColor;
}

.nav-primary {
  font-family: $title;
  font-size: 1.4rem;
  margin-top: 0;
  ul {
    list-style-type: none;
    text-align: center;
    margin: 0;
  }
  li {
    display: inline;
    margin: 0;
    &:not(:last-of-type) {
      margin-right: $base-spacing;
    }
  }
  a {
    text-decoration: none;
    font-weight: bold;
    line-height: 45px;
    text-transform: uppercase;
    display: inline-block;
  }

  @media (max-width: $breakpoint-large - 1px) {

    li {
      &:not(:last-of-type) {
        margin-right: 25px !important;
      }

    &:first-of-type {
      display: none;
    }
  }
}

  @media (max-width: $breakpoint-nav) {
    a.button {
      border: none;
      border-radius: 0;
      padding: 0;
      &:hover {
        background: transparent;
        color:$blue;
      }
    }
  }

  @media (min-width: $breakpoint-nav) {
    ul {
      text-align: left;
      float: right;
    }
    li {
      float: left;
      display: inline-block;
      &:not(:last-of-type) {
        margin-right: $large-spacing;
      }
    }
  }
}

@media (max-width: $breakpoint-nav) {
  .header-primary {
    .col {
      width: 100%;
      margin: 0;
    }
    .nav-primary.col {
      padding-top: 0;
    }
  }
}
